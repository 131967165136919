import { Box, Button, ButtonProps, Icon, Link, Text, others, useDisclosure } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { nip19 } from "nostr-tools";
import dayjs from "dayjs";

import {
  DirectMessagesIcon,
  NotificationsIcon,
  ProfileIcon,
  RelayIcon,
  SearchIcon,
  SettingsIcon,
  LogoutIcon,
  NotesIcon,
  LightningIcon,
} from "../icons";
import useCurrentAccount from "../../hooks/use-current-account";
import accountService from "../../services/account";
import { useLocalStorage } from "react-use";
import ZapModal from "../event-zap-modal";
import PuzzlePiece01 from "../icons/puzzle-piece-01";
import Package from "../icons/package";
import Rocket02 from "../icons/rocket-02";
import { useBreakpointValue } from "../../providers/global/breakpoint-provider";
import KeyboardShortcut from "../keyboard-shortcut";
import useRecentIds from "../../hooks/use-recent-ids";
import { internalApps, internalTools } from "../../views/other-stuff/apps";
import { App, AppIcon } from "../../views/other-stuff/component/app-card";
import { useMemo } from "react";
import localSettings from "../../services/local-settings";
import useSubject from "../../hooks/use-subject";
export default function NavItems() {
const addClientTag = useSubject(localSettings.addClientTag);
  const location = useLocation();
  const account = useCurrentAccount();

  const donateModal = useDisclosure();
  const [lastDonate, setLastDonate] = useLocalStorage<number>("last-donate");

  const showShortcuts = useBreakpointValue({ base: false, md: true });

  const buttonProps: ButtonProps = {
    py: "2",
    justifyContent: "flex-start",
    variant: "link",
  };

  let active = "";
  if (location.pathname.startsWith("/n/")) active = "notes";
  else if (location.pathname === "/") active = "notes";
  else if (location.pathname.startsWith("/notifications")) active = "notifications";
  else if (location.pathname.startsWith("/dm")) active = "dm";
  else if (location.pathname.startsWith("/relays")) active = "relays";
  else if (location.pathname.startsWith("/settings")) active = "settings";
//  else if (location.pathname.startsWith("/search")) active = "search";
  else if (location.pathname.startsWith("/profile")) active = "profile";
  else if (
    account &&
    (location.pathname.startsWith("/u/" + nip19.npubEncode(account.pubkey)) ||
      location.pathname.startsWith("/u/" + account.pubkey))
  ) {
    active = "profile";
  }

  const { recent: recentApps } = useRecentIds("apps");
  const otherStuff = useMemo(() => {
    const internal = [...internalApps, ...internalTools];
    const apps = recentApps.map((id) => internal.find((app) => app.id === id)).filter(Boolean) as App[];
    if (apps.length > 3) {
      apps.length = 3;
    } else {
      if (apps.length < 3 && !apps.some((a) => a.id === "streams")) {
        apps.push(internal.find((app) => app.id === "streams")!);
      }
      if (apps.length < 3 && !apps.some((a) => a.id === "communities")) {
        apps.push(internal.find((app) => app.id === "communities")!);
      }
      if (apps.length < 3 && !apps.some((a) => a.id === "channels")) {
        apps.push(internal.find((app) => app.id === "channels")!);
      }
    }
    return apps;
  }, [recentApps]);

  return (
    <>
      <Button
        as={RouterLink}
        to="/?people=global"
        leftIcon={<NotesIcon boxSize={6} />}
        colorScheme={active === "notes" ? "primary" : undefined}
        {...buttonProps}
      >
        Global Timeline
            {showShortcuts && <KeyboardShortcut letter="g" requireMeta ml="auto" />}
      </Button>
      {account && (
        <>
          <Button
            as={RouterLink}
            to="/notifications"
            leftIcon={<NotificationsIcon boxSize={6} />}
            colorScheme={active === "notifications" ? "primary" : undefined}
            {...buttonProps}
          >
            Notifications
            {showShortcuts && <KeyboardShortcut letter="n" requireMeta ml="auto" />}
          </Button>
          <Button
            as={RouterLink}
            to={"/dm"}
            leftIcon={<DirectMessagesIcon boxSize={6} />}
            colorScheme={active === "dm" ? "primary" : undefined}
            {...buttonProps}
          >
            Messages
            {showShortcuts && <KeyboardShortcut letter="m" requireMeta ml="auto" />}
          </Button>
        </>
      )}
      {account?.pubkey && (
        <Button
          as={RouterLink}
          to={"/u/" + nip19.npubEncode(account.pubkey)}
          leftIcon={<ProfileIcon boxSize={6} />}
          colorScheme={active === "profile" ? "primary" : undefined}
          {...buttonProps}
        >
          Profile
        {showShortcuts && <KeyboardShortcut letter="p" requireMeta ml="auto" />}
        </Button>
      )}
      <Button
        as={RouterLink}
        to="/settings"
        leftIcon={<SettingsIcon boxSize={6} />}
        colorScheme={active === "settings" ? "primary" : undefined}
        {...buttonProps}
      >
        Settings
            {showShortcuts && <KeyboardShortcut letter="s" requireMeta ml="auto" />}
        </Button>
      {account && (
        <Button onClick={() => accountService.logout()} leftIcon={<LogoutIcon boxSize={6} />} {...buttonProps}>
          Logout
        </Button>
      )}
    </>
  );
}
